import i18next from "i18next"

i18next.init({
  fallbackLng: "en",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    useSuspense: false,
    wait: false,
  },
  // init with resources
  resources: {
    en: {
      translations: {
        index: {
          redirect: "Redirecting to local site...",
        },
        site: "uk",
        home: "Go to homepage",
        page404: {
          message: "You can get out of the Dark Side…",
          notes: "The page you are looking for is not available",
        },
        page500: {
          message: "Apologize, something was wrong",
          notes: "Try again or reload the page",
        },
        prelaunch: {
          seo: {
            title:
              "Collectibles, busts, action figurines, model kits | Fanhome",
            description:
              "If you're looking for action figurines, busts or model kits, you're in the right place. Welcome to Fanhome. Discover our new collections and so much more!",
          },
          h1: "Everything is<br/>about to change",
          intro:
            "Excited? We are too. Join us and be notified when we launch and be the first to access our special offers.",
          caption: "Get Ready",
          title:
            "Fanhome offers you the most unique collections and build up models, and it's almost here!",
          subtitle:
            "All our products are original in-house designs you won't find anywhere else",
          subscribe: {
            button: "I'm in",
            emailPlaceholder: "Your email address",
            error: "Ops..! Something wrong. Please Try again.",
            success: "You have subscribed succesfully.",
            invalidEmail: "Invalid E-mail",
            requiredField: "*Required field",
          },
          hilights: {
            title1: "An amazing journey",
            text1:
              "Immerse yourself into the universes you love and be inspired by the exclusive content.",
            title2: "Inspiring content",
            text2:
              "Each shipment includes awesome magazines with behind the scene material, little-known information and rare images.",
          },
          offer: {
            title: "Check out our first available build up model",
            product: "Build the iconic<br/>Dodge Charger R/T",
            description:
              "Relive all the energy of the Fast & Furious saga and feel like another member of the family assembling this incredible 1:8 scale model. Exceptional details and quality!",
            cta: "View Build Up Model",
            link: "/uk/movie-series/dodge-build-up/",
            stamp: {
              text: "First stage<br/>only",
              highlightedText: "£0.99",
            },
          },
          launchDate: "2020-12-09T14:00:00+00:00",
        },
        notifyme: {
          uptitle: "Please notify me",
          uptitleConfirm: "We are working on it!",
          title: "Don't miss out!",
          sendCta: "Notify me",
          description:
            "When this product becomes available, we will email you to let you know that you can now subscribe to this collection. We may also email you about similar products that may interest you. By submitting this form, you confirm that you have read and fully understood our <a href='/uk/privacy-policy'>Privacy Policy</a>.",

          placeholder: "Your email",
          confirm:
            "When the product is available, we will immediately let you know. You love the story, we make it real!",
          goToHome: "Go to homepage",
        },
        countdown: {
          days: {
            singular: "day",
            plural: "days",
          },
          hours: {
            singular: "hour",
            plural: "hours",
          },
          minutes: {
            singular: "minute",
            plural: "minutes",
          },
          seconds: {
            singular: "second",
            plural: "seconds",
          },
        },
        playVideo: "PLAY VIDEO",
        seeAllVideos: "See all videos",
        seeVideo: "See video",
        chooseCountry: "Choose country",
        startCancellation: {
          title: "Cancellation | Fanhome",
          description:
            "Dear collector, <br/>enter the email you used for the account you want to cancel.  You will no longer receive any commercial communications from us.",
          button: "SEND",
          responceSuccess:
            "Check the inbox of the email you entered and click on the link to finalize the procedure",
          responceError: "Opps, an error has occured. Please retry",
        },
        confirmCancellation: {
          title: "Cancellation confirmation",
          responceSuccess:
            "Dear Collector, <br/>Your request has been completed, in accordance with our privacy policy.",
          responceError: "An error has occured during the process",
          errorTokenExpired: "Ops... the page is not valid.",
          redirectMessage:
            "You will be redirected to the homepage in <sec> seconds, otherwise",
          clickHere: "click here.",
        },
        logo: {
          title: "Fanhome UK",
          description:
            "Be welcome to Fanhome UK! Discover our new collections and so much more!",
        },
        login: {
          userName: "Hi, {{userName}}",
          title: "Welcome to Fanhome",
          signWithEmail: "Sign in email address",
          signWithGoogle: "Sign in with Google",
          signWithFacebook: "Sign in with Facebook",
          signIn: "Sign In",
          or: "or",
          email: "Email address",
          password: "Password",
          keepMeLogin: "Keep me login",
          login: "Log In",
          cta: "Log in",
          forgotPassword: "Forgot your password?",
          error: "An error occurred processing your login. Please try again",
        },
        // Index page web map
        index_page_other: "Other",
        index_page_past_collection: "Past collection",
        search: {
          label: "What are you looking for?",
          no_issue_search:
            "Sorry, we couldn't find any results for your search: {{name}}. Try again with other words.",
        },
        pre_order_label: "PRE-ORDER",
        ecommerce: {
          moreInfo: "More Info",
          outOfStock: "Out of stock",
          comingNextMonth: "Coming Next month:",
          labelAvailableDate: "Available ",
          comingSoon: "Coming soon",
          months: {
            january: "Jan",
            february: "Feb",
            march: "Mar",
            april: "Apr",
            may: "May",
            june: "Jun",
            july: "Jul",
            august: "Aug",
            september: "Sep",
            october: "Oct",
            november: "Nov",
            december: "Dec",
          },
        },
        configurator_receive_faster: "Do you want to receive it all at once?",
      },
    },
    en_US: {
      translations: {
        index: {
          redirect: "Redirecting to local site...",
        },
        site: "us",
        home: "Go to homepage",
        page404: {
          message: "You can get out of the Dark Side…",
          notes: "The page you are looking for is not available",
        },
        page500: {
          message: "Apologize, something was wrong",
          notes: "Try again or reload the page",
        },
        prelaunch: {
          seo: {
            title:
              "Collectibles, busts, action figurines, model kits | Fanhome",
            description:
              "If you're looking for action figurines, busts or model kits, you're in the right place. Welcome to Fanhome. Discover our new collections and so much more!",
          },
          h1: "Everything is<br/>about to change",
          intro:
            "Excited? We are too. Join us and be notified when we launch and be the first to access our special offers.",
          caption: "Get Ready",
          title:
            "Fanhome offers you the most unique collections and build-up models and it's almost here!",
          subtitle:
            "All of our products are original, in-house designs you won't find anywhere else",
          subscribe: {
            button: "I'm in",
            emailPlaceholder: "Your email address",
            error: "Ops..! Something wrong. Please Try again.",
            success: "You have subscribed succesfully.",
            invalidEmail: "Invalid E-mail",
            requiredField: "*Required field",
          },
          hilights: {
            title1: "An amazing journey",
            text1:
              "Immerse yourself in the universes you love and be inspired by the exclusive content.",
            title2: "Inspiring content",
            text2:
              "Each shipment includes awesome magazines with behind-the-scenes material, little-known information and rare images.",
          },
          launchDate: "2020-05-03T14:00:00+00:00",
        },
        notifyme: {
          uptitle: "Please notify me",
          uptitleConfirm: "We'll come back to you soon!",
          title: "Don't miss out!",
          sendCta: "Notify me",
          description:
            "When this product becomes available, we will email you to let you know that you can now subscribe to this collection. We may also email you about similar products that may interest you.",
          placeholder: "Your email",
          confirm:
            "As soon as this incredible collection is available for purchase, we will let you know. Let's keep in touch!",
          goToHome: "Go to homepage",
        },
        countdown: {
          days: {
            singular: "day",
            plural: "days",
          },
          hours: {
            singular: "hour",
            plural: "hours",
          },
          minutes: {
            singular: "minute",
            plural: "minutes",
          },
          seconds: {
            singular: "second",
            plural: "seconds",
          },
        },
        playVideo: "PLAY VIDEO",
        chooseCountry: "Choose country",
        startCancellation: {
          title: "Cancellation | Fanhome",
          description:
            "Dear collector, <br/>enter the email you used for the account you want to cancel.  You will no longer receive any commercial communications from us.",
          button: "SEND",
          responceSuccess:
            "Check the inbox of the email you entered and click on the link to finalize the procedure",
          responceError: "Opps, an error has occured. Please retry",
        },
        confirmCancellation: {
          title: "Cancellation confirmation",
          responceSuccess:
            "Dear Collector, <br/>Your request has been completed, in accordance with our privacy policy.",
          responceError: "An error has occured during the process",
          errorTokenExpired: "Ops... the page is not valid.",
          redirectMessage:
            "You will be redirected to the homepage in <sec> seconds, otherwise",
          clickHere: "click here.",
        },
        logo: {
          title: "Fanhome US",
          description:
            "Be welcome to Fanhome US! Discover our new collections and so much more!",
        },
        login: {
          userName: "Hi, {{userName}}",
          title: "Welcome to Fanhome",
          signWithEmail: "Sign in email address",
          signWithGoogle: "Sign in with Google",
          signWithFacebook: "Sign in with Facebook",
          signIn: "Sign In",
          or: "or",
          email: "Email address",
          password: "Password",
          keepMeLogin: "Keep me login",
          login: "Log In",
          cta: "Log in",
          error: "An error occurred processing your login. Please try again",
          forgotPassword: "Forgot your password?",
        },
        // Index page web map
        index_page_other: "Other",
        index_page_past_collection: "Past collection",
        search: {
          label: "What are you looking for?",
          no_issue_search:
            "Sorry, we couldn't find any results for your search: {{name}}. Try again with other words.",
        },
        pre_order_label: "PRE-ORDER",
        ecommerce: {
          moreInfo: "More Info",
          outOfStock: "Out of stock",
        },
        configurator_receive_faster: "Do you want to receive it all at once?",
      },
    },
    de: {
      translations: {
        index: {
          redirect: "Weiterleitung zur lokalen Site...",
        },
        site: "de",
        home: "Zurück zur vorherigen Seite",
        page404: {
          message: "Ups! Sieht so aus, als wärst du verloren!",
          notes: "Die gesuchte Seite konnte leider nicht gefunden werden",
        },
        page500: {
          message: "Ups! Sieht so aus, als ob etwas schief geht!",
          notes: "Versuchen Sie es erneut oder laden Sie die Seite neu",
        },
        prelaunch: {
          seo: {
            title: "Sammlerstücke, Büsten, Actionfiguren | Fanhome",
            description:
              "Wenn du nach Actionfiguren, Büsten oder Modellbausätzen suchst, bist du hier genau richtig. Entdecke unsere neuen Kollektionen und vieles mehr!",
          },
          h1: "Eine grosse veränderung steht vor der tür",
          intro:
            "Freust du dich schon? Wir auch. Melde dich an! Wir verständigen dich, wenn wir etwas Neues herausbringen, und du erfährst als erster von unseren Sonderaktionen.",
          caption: "Bereite dich vor",
          title:
            "Fanhome bietet dir einzigartige Kollektionen und Nachbaumodelle an und du bist nahe dran!",
          subtitle:
            "Alle unsere Produkte sind hauseigenes Originaldesign und nur bei uns erhältlich.",
          subscribe: {
            button: "Ich bin dabei",
            emailPlaceholder: "Deine E-Mail-Adresse",
            error: "Ops…! Etwas ist falsch. Bitte versuche es erneut.",
            success: "Du hast dich erfolgreich abonniert.",
            invalidEmail: "Ungültige E-Mail",
            requiredField: "*Pflichtfelder",
          },
          hilights: {
            title1: "Eine wunderbare reise",
            text1:
              "Tauche ein in deine Lieblingswelten und lass dich von unserem exklusiven Inhalt inspirieren.",
            title2: "Inspirierender inhalt",
            text2:
              "Jede Lieferung beinhaltet fantastische Magazine mit Hintergrundmaterial, wenig bekannten Infos und seltenen Bildern.",
          },
          offer: {
            title:
              "Wirf einen Blick auf unser erstes erhältliches Nachbaumodell",
            product: "Bau die legendäre Iron Man Mark III Rüstung",
            description:
              "Helden werden nicht geboren. Sie werden gebaut. Bau Schritt für Schritt die legendäre Iron Man Mark III-Rüstung zusammen, die Tony Stark zum legendären Helden des Marvel-Universums gemacht hat.",
            cta: "Nachbaumodell anzeigen",
            link: "/de/marvel/iron-man-modell/",
            stamp: {
              text: "1. Phase nur",
              highlightedText: "€1,00",
            },
          },
          launchDate: "2020-12-09T14:00:00+01:00",
        },
        notifyme: {
          uptitle: "Bitte, mich benachrichtigen",
          uptitleConfirm: "Wir arbeiten daran! ",
          title: "Verpasse es nicht!",
          sendCta: "Mich benachrichtigen",
          description:
            "Sobald dieses Produkt verfügbar ist, informieren wir dich per E-Mail darüber, dass du die Kollektion jetzt abonnieren kannst. Und gelegentlich informieren wie dich auch per E-Mail über ähnliche Produkte, die dich interessieren könnten. Mit Absenden dieses Formulars bestätigst du, dass du unsere<a href='/de/privacy-policy/'>Datenschutzerklärung</a> gelesen und verstanden hast.",

          placeholder: "Deine E-Maill",
          confirm:
            "Sobald das Produkt verfügbar ist, informieren wir dich umgehend darüber. Du liebst die Geschichten, wir machen sie wahr!",
          goToHome: "Zur Homepage gehen",
        },
        countdown: {
          days: {
            singular: "tage",
            plural: "tage",
          },
          hours: {
            singular: "stunden",
            plural: "stunden",
          },
          minutes: {
            singular: "minuten",
            plural: "minuten",
          },
          seconds: {
            singular: "sekunden",
            plural: "sekunden",
          },
        },
        playVideo: "VIDEO ABSPIELEN",
        seeAllVideos: "Alle videos ansehen",
        chooseCountry: "Land auswählen",
        startCancellation: {
          title: "Stornierung / Fanhome",
          description:
            "Lieber Sammler, <br/>geben Sie bitte die E-Mail-Adresse ein, die Sie für das Konto verwendet haben, das Sie Stornieren möchten. Sie werden keine kommerzielle Mitteilungen mehr von uns erhalten.",
          button: "SENDEN",
          responceSuccess:
            "Überprüfen Sie den Posteingang der von Ihnen eingegebenen E-Mail und klicken Sie auf den Link zur Bestätigung.",
          responceError:
            "Ops, ein Fehler ist aufgetreten. Bitte erneut versuchen",
        },
        confirmCancellation: {
          title: "Stornierungsbestätigung",
          responceSuccess:
            "Lieber Sammler, <br/>Ihre Anfrage wurde gemäß unserer Datenschutzrichtlinie abgeschlossen.",
          responceError: "Während des Vorgangs ist ein Fehler aufgetreten",
          errorTokenExpired: "Ops... die Seite ist ungültig.",
          redirectMessage:
            "Sie werden in <sec> Sekunden auf die Homepage weitergeleitet, ansonsten",
          clickHere: "klicken Sie hier.",
        },
        logo: {
          title: "Fanhome DE",
          description: "Entdecken unsere Neu Sammlungen und so viel Mehr!",
        },

        login: {
          userName: "Hallo, {{userName}}",
          title: "Willkommen bei Fanhome",
          signWithEmail: "mit E-Mail-Adresse anmelden",
          signWithGoogle: "it Google anmelden",
          signWithFacebook: "mit Facebook anmelden",
          signIn: "Anmelden",
          or: "oder",
          email: "E-Mail-Adresse",
          password: "Passwort",
          keepMeLogin: "Ich möchte angemeldet bleiben",
          login: "Anmelden",
          cta: "Anmelden",
          forgotPassword: "Hast du dein Passwort vergessen?",
          error:
            "Es ist ein Fehler aufgetreten beim anmelden. Bitte versuche es erneut",
        },
        // Index page web map
        index_page_other: "Andere",
        index_page_past_collection: "Vergangene Sammlung",
        search: {
          label: "Wonach suchst du?",
          no_issue_search:
            "Leider haben wir keine Ergebnisse für die Suchanfrage {{name}} gefunden. Versuche es noch einmal mit anderen Suchbegriffen.",
        },
        pre_order_label: "VORBESTELLUNG",
        ecommerce: {
          moreInfo: "Mehr Infos",
          outOfStock: "Nicht auf lager",
          comingNextMonth: "Kommt nächsten Monat:",
          labelAvailableDate: "Verfügbar ",
          comingSoon: "DEMNÄCHST VERFÜGBAR",
          months: {
            january: "Jan",
            february: "Feb",
            march: "Mar",
            april: "Apr",
            may: "May",
            june: "Jun",
            july: "Jul",
            august: "Aug",
            september: "Sep",
            october: "Oct",
            november: "Nov",
            december: "Dec",
          },
        },
        configurator_receive_faster: "Möchtest du es schneller erhalten?",
      },
    },
    de_AT: {
      translations: {
        index: {
          redirect: "Weiterleitung zur lokalen Site...",
        },
        site: "de",
        home: "Zurück zur vorherigen Seite",
        page404: {
          message: "Ups! Sieht so aus, als wärst du verloren!",
          notes: "Die gesuchte Seite konnte leider nicht gefunden werden",
        },
        page500: {
          message: "Ups! Sieht so aus, als ob etwas schief geht!",
          notes: "Versuchen Sie es erneut oder laden Sie die Seite neu",
        },
        prelaunch: {
          seo: {
            title: "Sammlerstücke, Büsten, Actionfiguren | Fanhome",
            description:
              "Wenn du nach Actionfiguren, Büsten oder Modellbausätzen suchst, bist du hier genau richtig. Entdecke unsere neuen Kollektionen und vieles mehr!",
          },
          h1: "Eine grosse veränderung steht vor der tür",
          intro:
            "Freust du dich schon? Wir auch. Melde dich an! Wir verständigen dich, wenn wir etwas Neues herausbringen, und du erfährst als erster von unseren Sonderaktionen.",
          caption: "Bereite dich vor",
          title:
            "Fanhome bietet dir einzigartige Kollektionen und Nachbaumodelle an und du bist nahe dran!",
          subtitle:
            "Alle unsere Produkte sind hauseigenes Originaldesign und nur bei uns erhältlich.",
          subscribe: {
            button: "Ich bin dabei",
            emailPlaceholder: "Deine E-Mail-Adresse",
            error: "Ops…! Etwas ist falsch. Bitte versuche es erneut.",
            success: "Du hast dich erfolgreich abonniert.",
            invalidEmail: "Ungültige E-Mail",
            requiredField: "*Pflichtfelder",
          },
          hilights: {
            title1: "Eine wunderbare reise",
            text1:
              "Tauche ein in deine Lieblingswelten und lass dich von unserem exklusiven Inhalt inspirieren.",
            title2: "Inspirierender inhalt",
            text2:
              "Jede Lieferung beinhaltet fantastische Magazine mit Hintergrundmaterial, wenig bekannten Infos und seltenen Bildern.",
          },
          offer: {
            title:
              "Wirf einen Blick auf unser erstes erhältliches Nachbaumodell",
            product: "Bau die legendäre Iron Man Mark III Rüstung",
            description:
              "Helden werden nicht geboren. Sie werden gebaut. Bau Schritt für Schritt die legendäre Iron Man Mark III-Rüstung zusammen, die Tony Stark zum legendären Helden des Marvel-Universums gemacht hat.",
            cta: "Nachbaumodell anzeigen",
            link: "/de/marvel/iron-man-modell/",
            stamp: {
              text: "1. Phase nur",
              highlightedText: "€1,00",
            },
          },
          launchDate: "2020-12-09T14:00:00+01:00",
        },
        notifyme: {
          uptitle: "Bitte, mich benachrichtigen",
          uptitleConfirm: "Wir arbeiten daran! ",
          title: "Verpasse es nicht!",
          sendCta: "Mich benachrichtigen",
          description:
            "Sobald dieses Produkt verfügbar ist, informieren wir dich per E-Mail darüber, dass du die Kollektion jetzt abonnieren kannst. Und gelegentlich informieren wie dich auch per E-Mail über ähnliche Produkte, die dich interessieren könnten. Mit Absenden dieses Formulars bestätigst du, dass du unsere<a href='/de/privacy-policy/'>Datenschutzerklärung</a> gelesen und verstanden hast.",

          placeholder: "Deine E-Maill",
          confirm:
            "Sobald das Produkt verfügbar ist, informieren wir dich umgehend darüber. Du liebst die Geschichten, wir machen sie wahr!",
          goToHome: "Zur Homepage gehen",
        },
        countdown: {
          days: {
            singular: "tage",
            plural: "tage",
          },
          hours: {
            singular: "stunden",
            plural: "stunden",
          },
          minutes: {
            singular: "minuten",
            plural: "minuten",
          },
          seconds: {
            singular: "sekunden",
            plural: "sekunden",
          },
        },
        playVideo: "VIDEO ABSPIELEN",
        chooseCountry: "Land auswählen",
        startCancellation: {
          title: "Stornierung / Fanhome",
          description:
            "Lieber Sammler, <br/>geben Sie bitte die E-Mail-Adresse ein, die Sie für das Konto verwendet haben, das Sie Stornieren möchten. Sie werden keine kommerzielle Mitteilungen mehr von uns erhalten.",
          button: "SENDEN",
          responceSuccess:
            "Überprüfen Sie den Posteingang der von Ihnen eingegebenen E-Mail und klicken Sie auf den Link zur Bestätigung.",
          responceError:
            "Ops, ein Fehler ist aufgetreten. Bitte erneut versuchen",
        },
        confirmCancellation: {
          title: "Stornierungsbestätigung",
          responceSuccess:
            "Lieber Sammler, <br/>Ihre Anfrage wurde gemäß unserer Datenschutzrichtlinie abgeschlossen.",
          responceError: "Während des Vorgangs ist ein Fehler aufgetreten",
          errorTokenExpired: "Ops... die Seite ist ungültig.",
          redirectMessage:
            "Sie werden in <sec> Sekunden auf die Homepage weitergeleitet, ansonsten",
          clickHere: "klicken Sie hier.",
        },
        logo: {
          title: "Fanhome AT",
          description: "Entdecken unsere Neu Sammlungen und so viel Mehr!",
        },
        login: {
          userName: "Hallo, {{userName}}",
          title: "Welcome to Fanhome",
          signWithEmail: "mit E-Mail-Adresse anmelden",
          signWithGoogle: "Inloggen met Google",
          signWithFacebook: "Inloggen met Facebook",
          signIn: "Anmelden",
          or: "oder",
          email: "E-mailadres",
          password: "Wachtwoord",
          keepMeLogin: "Ik wil ingelogd blijven",
          login: "Inloggen",
          cta: "Inloggen",
          forgotPassword: "Hast du dein Passwort vergessen?",
          error:
            "Es ist ein Fehler aufgetreten beim anmelden. Bitte versuche es erneut",
        },
        // Index page web map
        index_page_other: "Andere",
        index_page_past_collection: "Vergangene Sammlung",
        search: {
          label: "Wonach suchst du?",
          no_issue_search:
            "Leider haben wir keine Ergebnisse für die Suchanfrage {{name}} gefunden. Versuche es noch einmal mit anderen Suchbegriffen.",
        },
        pre_order_label: "VORBESTELLUNG",
        ecommerce: {
          moreInfo: "Mehr Info",
          outOfStock: "Nicht auf lager",
        },
        configurator_receive_faster: "Möchtest du es schneller erhalten?",
      },
    },
    nl: {
      translations: {
        index: {
          redirect: "Redirecting to local site...",
        },
        site: "nl",
        home: "Naar de homepage",
        page404: {
          message: "You can get out of the Dark Side…",
          notes: "The page you are looking for is not available",
        },
        page500: {
          message: "Apologize, something was wrong",
          notes: "Try again or reload the page",
        },
        prelaunch: {
          seo: {
            title:
              "Collectibles, busts, action figurines, model kits | Fanhome",
            description:
              "If you're looking for action figurines, busts or model kits, you're in the right place. Welcome to Fanhome. Discover our new collections and so much more!",
          },
          h1: "Everything is<br/>about to change",
          intro:
            "Excited? We are too. Join us and be notified when we launch and be the first to access our special offers.",
          caption: "Get Ready",
          title:
            "Fanhome offers you the most unique collections and build up models, and it's almost here!",
          subtitle:
            "All our products are original in-house designs you won't find anywhere else",
          subscribe: {
            button: "Ik doe mee!",
            emailPlaceholder: "Vul je e-mailadres in",
            error: "Ops..! Something wrong. Please Try again.",
            success: "You have subscribed succesfully.",
            invalidEmail: "Ongeldige e-mail",
            requiredField: "*Verplichte velden",
          },
          hilights: {
            title1: "An amazing journey",
            text1:
              "Immerse yourself into the universes you love and be inspired by the exclusive content.",
            title2: "Inspiring content",
            text2:
              "Each shipment includes awesome magazines with behind the scene material, little-known information and rare images.",
          },
          offer: {
            title: "Check out our first available build up model",
            product: "Build the iconic<br/>Dodge Charger R/T",
            description:
              "Relive all the energy of the Fast & Furious saga and feel like another member of the family assembling this incredible 1:8 scale model. Exceptional details and quality!",
            cta: "View Build Up Model",
            link: "/uk/movie-series/dodge-build-up/",
            stamp: {
              text: "First stage<br/>only",
              highlightedText: "£0.99",
            },
          },
          launchDate: "2020-12-09T14:00:00+00:00",
        },
        notifyme: {
          uptitle: "Laat me zeker iets weten",
          uptitleConfirm: "We zijn ermee bezig!",
          title: "Mis het niet!",
          sendCta: "Laat me iets weten",
          description:
            "Zodra dit product beschikbaar is, sturen we je een e-mail ter informatie en kun je je aanmelden voor deze verzameling. We kunnen je ook een e-mail sturen over vergelijkbare producten die je misschien interessant vindt. Door dit formulier te verzenden, bevestig je dat je ons <a href='/nl/privacy-policy/'>Privacybeleid</a> hebt gelezen en begrepen.",

          placeholder: "Je e-mail",
          confirm:
            "Zodra het product beschikbaar is, laten we je het onmiddellijk weten. Jij houdt van het verhaal, wij brengen het tot leven!",
          goToHome: "Naar de homepage",
        },
        countdown: {
          days: {
            singular: "day",
            plural: "days",
          },
          hours: {
            singular: "hour",
            plural: "hours",
          },
          minutes: {
            singular: "minute",
            plural: "minutes",
          },
          seconds: {
            singular: "second",
            plural: "seconds",
          },
        },
        playVideo: "VIDEO AFSPELEN",
        chooseCountry: "Choose country",
        startCancellation: {
          title: "Annulering| Fanhome",
          description:
            "Beste verzamelaar, <br/> voer het e-mailadres in dat u gebruikt hebt voor de account die u wilt opzeggen. U ontvangt dan niet langer commerciële mededelingen van ons.",
          button: "VERZENDEN",
          responceSuccess:
            "Check de inbox van de e-mail die u hebt ingevoerd en klik op de link om de procedure af te ronden",
          responceError: "Oeps, er is een fout opgetreden. Probeer het opnieuw",
        },
        confirmCancellation: {
          title: "Bevestiging van annulering",
          responceSuccess:
            "Beste verzamelaar, <br/> Uw aanvraag is afgehandeld in overeenstemming met ons privacybeleid.",
          responceError: "Er is een fout opgetreden tijdens het proces",
          errorTokenExpired: "Ops... the page is not valid.",
          redirectMessage:
            "U gaat over <sec> seconden door naar de homepage, anders",
          clickHere: " Klik hier.",
        },
        logo: {
          title: "Fanhome NL",
          description:
            "Be welcome to Fanhome UK! Discover our new collections and so much more!",
        },
        login: {
          userName: "Hallo, {{userName}}",
          title: "Welkom bij Fanhome",
          signWithEmail: "Přihlásit se pomocí své e-mailové adresy",
          or: "alebo",
          signWithGoogle: "Inloggen met Google",
          signWithFacebook: "Inloggen met Facebook",
          signIn: "Přihlásit se",
          email: "E-mailadres",
          password: "Wachtwoord",
          keepMeLogin: "Ik wil ingelogd blijven",
          login: "Inschrijven",
          cta: "Inschrijven",
          forgotPassword: "Wachtwoord vergeten?",
          error:
            "Er is een fout opgetreden bij het inloggen. Probeer het alsjeblieft opnieuw",
        },
        // Index page web map
        index_page_other: "Ander",
        index_page_past_collection: "Verleden collectie",
        search: {
          label: "Waar ben je naar op zoek?",
          no_issue_search:
            "Sorry, we konden geen resultaten vinden voor uw zoekopdracht: {{name}}. Probeer het opnieuw met een ander zoekwoord.",
        },
        pre_order_label: "VOORBESTELLING",
        ecommerce: {
          moreInfo: "Meer Info",
          outOfStock: "Niet voorradig",
        },
        configurator_receive_faster: "Je bestelling sneller ontvangen?",
      },
    },
  },
})

i18next.languages = ["en", "de"]

export default i18next
